import logo from './ezqr-logo.png';
import './App.css';
import QRCode from 'react-qr-code';
import { useState } from 'react';

function App() {
  var [qrValue, setQrValue] = useState("");
  
  function generateQRCode (inputText) {		    
    if (!inputText) {
      alert("Input a text");
      return;
    }
    setQrValue(inputText);
  }

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ display: 'flex', marginBottom: 0, alignItems: 'center' }}>
          <img src={logo} style={{ width: 150, height: 150, marginBottom: 0 }} alt="logo" />
          <h1>EZ QRs</h1>
        </div>
        <div style= {{width: '800px', maxWidth: '100%', margin: 'auto' }}>
          <p style={{ fontSize: '0.8em' }}>
            Just type the URL or text you want to encode into a QR code and click the button. The
            QR code will be generated and displayed for you to copy or download.
          </p>
          <form 
            style={{ 
              display: 'flex', 
              gap: 5, 
              width: '100%', 
              margin: 'auto', 
              marginBottom: 20,
              alignItems: 'center'
            }}
            onSubmit={(e) => {
              e.preventDefault();
              const qrText = document.getElementById("qr-text").value;
              generateQRCode(qrText);
            }}
          >
            <input 
              type="text" 
              className="material-input"
              id="qr-text" 
              placeholder="Text or URL to encode" 
              style={{ width: '70%' }} 
            />
            <button className="material-button" style={{ width: '30%' }} type="submit">Generate QR</button>
          </form>
          <div id="qrcode" style={{ height: 400, marginBottom: 50 }}>
          {qrValue &&
            <div>
              <p>{ qrValue }</p>
              <QRCode
                size={360}
                style={{ height: "auto", maxWidth: "100%", width: 360, padding: 0 }}
                value={qrValue}
                viewBox={`0 0 360 360`}
              />
            </div>
          }
          </div>
          <p style={{ fontSize: '0.8em', textAlign: 'justify', textJustify: 'inter-word'}}>
            No trials. No memberships. No logins. No spam. No ads. Just simple web
            tools that do what you need. You can check out my other tools
            at <a href="https://swissarmysite.com">SwissArmySite.com</a>.
            Use them as much as you need. All I ask is that, if you use them
            a lot, <a href="https://paypal.me/swissarmysite?country.x=US&locale.x=en_US">leave a tip</a>.
            And if there's other simple tools you want to see, you 
            can <a href="mail to:suggestions@swissarmysite.com">email me</a> and let me know.
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
